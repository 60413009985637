import { Injectable } from '@angular/core';
import { BehaviorSubject, from, map, Observable, switchMap, take, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { SecureStorageService } from 'src/app/services/secure-storage.service';

const apiURLs = {
  root: environment.apiURL,
  login: '/auth/login',
  logout: '/auth/logout',
  forgotPswd: '/auth/forgot-password',
  setPswd: '/auth/set-password',
  refreshToken: '/auth/refresh'
};

const TOKEN_KEY = 'token';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isAuthenticated: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);
  token = '';

  constructor(
    private http: HttpClient,
    private secureStorageService: SecureStorageService
  ) { 
    this.loadToken();
  }

  async loadToken() {
    const token = await this.secureStorageService.secureGet({ key: TOKEN_KEY + 'Access' });    
    if (token && token.value) {
      console.log('set token: ', token.value);
      this.token = token.value;
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }

  public getOTP(credentials : {email: string, password: string}): Observable<any> {
    return this.http.post(apiURLs.root + apiURLs.login, credentials);
  }

  public login(credentials : {email: string, password: string, code: number}): Observable<any> {
    return this.http.post(apiURLs.root + apiURLs.login, credentials).pipe(
      map((data: any) => data),
      switchMap(data => {
        console.log(data)
        this.setRefreshToken(data.refreshToken);
        return from(this.setToken(data.accessToken));
      }),
      tap(_ => {
        this.isAuthenticated.next(true);
      })
    );
  }

  public loginFromAdmin(accessToken: string) {
    this.isAuthenticated.next(true);
    return from(this.setToken(accessToken));
  }

  private logoutFromApi(): Observable<any> {
    return this.http.get(apiURLs.root + apiURLs.logout);
  }

  setToken(accessToken: string) {
    return this.secureStorageService.secureSet({key: TOKEN_KEY + 'Access', value: accessToken});
  }

  setRefreshToken(refreshToken: string) {
    return this.secureStorageService.secureSet({key: TOKEN_KEY + 'Refresh', value: refreshToken});
  }

  async getToken() {
    const token = await this.secureStorageService.secureGet({ key: TOKEN_KEY + 'Access' });
    return token; 
  }

  async getRefreshToken() {
    const token = await this.secureStorageService.secureGet({ key: TOKEN_KEY + 'Refresh' });
    return token; 
  }

  public logout(logoutFromApi: boolean = true): Promise<void> {
    this.isAuthenticated.next(false);
    if(logoutFromApi) this.logoutFromApi().pipe(take(1)).subscribe();
    this.secureStorageService.remove({key: 'clientName'});
    return this.secureStorageService.remove({key: TOKEN_KEY + 'Access'});
  }

  saveClientName(name: string) {
    return this.secureStorageService.secureSet({key: 'clientName', value: name});
  }

  async getClientName() {
    const name = await this.secureStorageService.secureGet({ key: 'clientName' });
    return name; 
  }

  public forgotPassword(email: {email: string}): Observable<any> {
    return this.http.post(apiURLs.root + apiURLs.forgotPswd, email);
  }

  public setPassword(data: {token: string | null, newPassword: string}): Observable<any> {
    return this.http.post(apiURLs.root + apiURLs.setPswd, data);
  }

  public refreshToken(refreshToken: string): Observable<any> {
    return this.http.get(apiURLs.root + apiURLs.refreshToken, {
        headers: {
          Authorization: `Bearer ${refreshToken}`
        }
    });
  }

}
