import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TableService } from './components/table/table.service';
import { Platform } from '@ionic/angular';
import { PlatformService } from './services/platform.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  isProd: boolean = environment.production;
  isMobile: boolean | null = false;

  public appPages = [
    { title: 'My Portfolio', url: '/dashboard', icon: 'dashboard' },
    { title: 'Change Requests', url: '/change-requests-history', icon: 'history' },
    { title: 'Documents', url: '/documents', icon: 'documents' },
    { title: 'Tools', url: '/tools', icon: 'calculator' },
    { title: 'My Profile', url: '/profile', icon: 'user' },
  ];
  
  menuOpen: boolean = false;

  constructor(
    private tableService: TableService,
    private platformService: PlatformService,
  ) {}
  
  ngOnInit() {
    this.platformService.initializePlatform().then(() => {
      this.isMobile = this.platformService.isMobileDevice();
    });
  }

  toggleMenu(state?: boolean) {
    console.log(state)
    if (state) {
      this.menuOpen = true;
    } else {
      this.menuOpen = !this.menuOpen;
      this.tableService.recalculate(true);
    }
  }
}
